import { SafeAreaView, Text } from 'react-native'

const LoginPage: React.FC = () => {
  return (
    <SafeAreaView testID="LoginPage">
      <Text>Página de login</Text>
    </SafeAreaView>
  )
}

export default LoginPage
